import {A2UNativeBrowser} from "a2u-capacitor-native-browser-plugin";
import {AbIAPClientStripe} from "./helpers/iap/AbIAPClientStripe";
import {StripeWebPlugin} from "./helpers/iap/StripeWebPlugin";
import {IAPManager} from "./helpers/iap/IAPManager";
import {Camera} from "@capacitor/camera";
import {ImagePickerCustomElements} from "./helpers/image-picker/ImagePickerCustomElements";
import {AuthPromoCodePlugin} from "./helpers/Auth/AuthPromoCodePlugin"

export const moduleConfig = {
  plugins: {A2UNativeBrowser, AbIAPClient: AbIAPClientStripe, StripeWebPlugin, IAPManager, CapacitorCamera: Camera, ImagePickerCustomElements, AuthPromoCodePlugin},

  mixin: {
    async created() {
      
            const initImagePickerCustomElements = () => {
                try {
                    const plugin = this.plugins.ImagePickerCustomElements;

                    if (!plugin) {
                        return;
                    }

                    const instance = new plugin();
                    instance.init();
                } catch (e) {
                    console.error('ImagePickerCustomElements error:', e);
                }
            }; initImagePickerCustomElements();              
        
    },

    async beforeUnmount() {
      
    }
  }
}
